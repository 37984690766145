import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

const IndexPage = ({ data, location }) => {
  const posts = data.allMdx.nodes

  return (
      <Layout location={location}>
        <Helmet>
          <title>Eric Migicovsky's Blog</title>
          <meta name="title" content="Eric Migicovsky's Blog" />
          <meta name="description" content="Blog posts by Eric Migicovsky" />
          <link rel="icon" href="/bc-notes.png" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ericmigi.com" />
          <meta property="og:title" content="Eric Migicovsky's Blog" />
          <meta property="og:description" content="Blog posts by Eric Migicovsky" />
          <meta property="og:image" content="https://ericmigi.com/indexsocial.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content="https://ericmigi.com" />
          <meta name="twitter:title" content="Eric Migicovsky's Blog" />
          <meta name="twitter:description" content="Blog posts by Eric Migicovsky" />
          <meta name="twitter:image" content="https://ericmigi.com/indexsocial.png" />
        </Helmet>
      <nav className="mb-8">
        <a 
          href="https://twitter.com/ericmigi" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-indigo-600 hover:text-indigo-800 mr-4"
        >
          Twitter
        </a>
        <a
          href="https://bsky.app/profile/ericmigi.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 hover:text-indigo-800 mr-4"
        >
          Bluesky
        </a>
        <a 
          href="https://www.chartwellspeakers.com/speaker/eric-migicovsky/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-indigo-600 hover:text-indigo-800 mr-4"
        >
          Speaking
        </a>
        <a 
          href="mailto:eric@ericmigi.com" 
          className="text-indigo-600 hover:text-indigo-800 mr-4"
        >
          Contact
        </a>
        <a 
          href="/rss.xml" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-indigo-600 hover:text-indigo-800"
        >
          RSS
        </a>
      </nav>
      <section className="mb-8 max-w-2xl">
        <h2 className="text-xl mb-4 font-bold">About Me</h2>
        <p className="mb-8 text-gray-600 leading-relaxed">
          I'm an inventor who loves creating new products, including Pebble (the
          first smartwatch) and <a href="https://beeper.com" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800">Beeper</a> (a universal chat app). I also spent 4 years as a partner at <a href="https://ycombinator.com" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800">Y Combinator</a>. I enjoy advising early stage startups - especially hardware! 
        </p>
      </section>
      <section>
        <h2 className="text-xl mb-4 font-bold">Blog Posts</h2>
        <ul className="pl-5">
          {posts.map(post => {
            const isExternal = post.frontmatter.type === 'external'
            const domain = isExternal ? new URL(post.frontmatter.externalUrl).hostname.replace('www.', '') : null

            return (
              <li key={post.id} className="mb-3 min-w-0 before:content-['—'] before:absolute before:-ml-5 before:text-gray-400 relative">
                <div className="flex flex-wrap items-baseline gap-1">
                  {isExternal ? (
                    <a
                      href={post.frontmatter.externalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 hover:text-indigo-800 inline-flex items-center gap-1"
                    >
                      <span className="break-words">{post.frontmatter.title}</span>
                    </a>
                  ) : (
                    <Link
                      to={`/blog/${post.frontmatter.slug}`}
                      className="text-indigo-600 hover:text-indigo-800 break-words"
                    >
                      {post.frontmatter.title}
                    </Link>
                  )}
                  <span className="text-sm text-gray-600 whitespace-nowrap">
                    {isExternal && `(${domain})`} [{post.frontmatter.date}]
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {draft: {ne: true}}}
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          type
          slug
          externalUrl
        }
      }
    }
  }
`

export default IndexPage
